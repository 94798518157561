@import "../../../../../../../../app_purina_core/cartridge/client/default/scss/experience/components/assets/articleContent";

#homepage-Purina_AU {
    .experience-assets-articleContent {
        .articleContent-buttons {
            .btn {
                transition: none;

                &:hover {
                    transform: none;
                }
                
                @include media-breakpoint-only(lg) {
                    margin: 0 0 16px;
                }

                &.btn-secondary-link {
                    @include media-breakpoint-down(lg) {
                        margin-top: 0;
                    }

                    @include media-breakpoint-down(xs) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

.experience-assets-articleContent {
    .articleContent-buttons {
        .btn {
            transition: none !important;

            &:hover {
                transform: none !important;
            }
        }
    }
}
