@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./articleContent__typography";

.articleContent {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 24px;
    }

    &.margins-enabled {
        margin-top: 16px;

        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }
    }

    &.layout-full-width {
        .articleContent-image {
            width: 100%;
            max-height: 530px;
        }
    }

    &.article-align-image {
        @include media-breakpoint-up(lg) {
            padding: 30px;

            .articleContent-image-container-cut {
                display: flex;
                align-items: center;
                justify-content: center;

                .articleContent-image {
                    width: auto;
                }
            }
        }
    }
}

.articleContent-content {
    width: 100%;

    @include media-breakpoint-up(lg) {
        display: flex;
        padding-right: 35px;
        width: 50%;
        padding: 0 80px;
        align-content: center;
        justify-content: center;
        flex-direction: column;

        .read-more & {
            width: 100%;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-right: 70px;
        width: 50%;

        .read-more & {
            width: 100%;
        }
    }
}

.article-content-title {
    margin-bottom: 16px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 24px;
    }
}

.articleContent-content-text {
    overflow: hidden;
    margin-top: 24px;

    p {
        margin-bottom: 0;

        /* stylelint-disable-next-line */
        &:not(:first-of-type) {
            /* stylelint-disable-next-line */
            br {
                margin-top: 45px;
            }
        }
    }

    .read-more & {
        max-height: none;
    }
}

.articleContent-image-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
        overflow: hidden;
    }

    .read-more & {
        max-height: none;
    }
}

.articleContent-content-wrapper {
    padding: 32px 24px;
    margin: 0;
}

.articleContent-image-container-cut {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        width: 50%;
    }

    @include media-breakpoint-up(xl) {
        display: block;
        width: 50%;
    }

    .read-more & {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.articleContent-image-container-full-width {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    .read-more & {
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.articleContent-buttons {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
    }

    .read-more & {
        display: none;
    }

    .btn {
        border-radius: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: transform 0.4s;
        white-space: nowrap;
        width: 100%;

        @include media-breakpoint-up(lg) {
            margin-right: 10px;
            width: auto;
        }

        /* stylelint-disable-next-line */
        &:hover {
            transform: scale(1.03);
        }

        /* stylelint-disable-next-line */
        &:focus {
            text-decoration: none;
        }

        &.btn-secondary-link {
            @include media-breakpoint-down(lg) {
                margin-top: 24px;
            }
        }
    }
}

.articleContent-image {
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 100%;
        max-height: 530px;
    }
}
.experience-component {
/* style for left align */
    .content-text-align-left-for-small {
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    .content-text-align-left-for-medium {
        @include media-breakpoint-only(md) {
            text-align: left;
        }
    }

    .content-text-align-left-for-large {
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    /* style for right align */
    .content-text-align-right-for-small {
        @include media-breakpoint-down(sm) {
            text-align: right;
        }
    }

    .content-text-align-right-for-medium {
        @include media-breakpoint-only(md) {
            text-align: right;
        }
    }

    .content-text-align-right-for-large {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }

    /* style for center align */
    .content-text-align-center-for-small {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
    .content-text-align-center-for-medium {
        @include media-breakpoint-only(md) {
            text-align: center;
        }
    }
    .content-text-align-center-for-large {
        @include media-breakpoint-up(lg) {
            text-align: center;
        }
    }
}

#homepage-Purina_AU {
    .contentWithImage-content-text {
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}
